import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { Backdrop, CircularProgress, Container } from "@mui/material";

import Footer from "./Footer";
import NavMenu from "./NavMenu";

const Article = lazy(() => import("../views/Articles/Article"));
const ArticlesList = lazy(() => import("../views/Articles/ArticlesList"));
const ArticlesListByCategory = lazy(
  () => import("../views/Articles/ArticlesListByCategory")
);
const Contact = lazy(() => import("../views/Contact"));
const FAQ = lazy(() => import("../views/FAQ"));
const Home = lazy(() => import("../views/Home"));
const Solution = lazy(() => import("../views/Solution"));
const MentionsLegales = lazy(() => import("./MentionsLegales"));

const Loading = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const App = () => {
  return (
    <>
      <NavMenu />
      <Container
        maxWidth={false}
        component="main"
        sx={{ paddingY: 2 }}
        disableGutters
      >
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="solution" element={<Solution />} />
            <Route path="contact" element={<Contact />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="articles" element={<ArticlesList />} />
            <Route
              path="articles/:categorySlug"
              element={<ArticlesListByCategory />}
            />
            <Route path="article/:articleSlug" element={<Article />} />
            <Route path="mentions_legales" element={<MentionsLegales />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Suspense>
      </Container>
      <Footer />
    </>
  );
};

interface Link {
  link: { pathname: string };
  label: string;
  pages?: undefined;
}

interface SubLink {
  pages: Link[];
  label: string;
  link?: undefined;
}

export const pageList = [
  { link: { pathname: "/solution" }, label: "Notre solution" },
  { link: { pathname: "/contact" }, label: "Nous contacter" },
  { link: { pathname: "/faq" }, label: "F.A.Q." },
  { link: { pathname: "/articles" }, label: "En découvrir plus" },
] as (Link | SubLink)[];

export default App;
