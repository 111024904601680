import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

import { pageList } from "../";
import Logo from "./angelia_white.svg";

const NavDrawer = ({
  mobileOpen,
  handleDrawerToggle,
}: {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}) => {
  const [resourceOpen, setResourceOpen] = useState(false);
  const resourceHandleClick = () => {
    setResourceOpen(!resourceOpen);
  };
  const handleDrawerToggleInside = () => {
    handleDrawerToggle();
    setResourceOpen(false);
  };
  return (
    <Box component="nav">
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggleInside}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 180 },
        }}
        PaperProps={{
          sx: {
            backgroundColor: "#011696",
            color: "#fff",
          },
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Button
            component={RouterLink}
            to={{ pathname: "/" }}
            sx={{ color: "#fff" }}
            onClick={handleDrawerToggleInside}
          >
            <img alt="Angelia" src={Logo} width={101} height={68} />
          </Button>
          <Divider color="#fff" />
          <List
            sx={{
              "& .MuiListItemButton-root:hover": {
                bgcolor: "#2B21A3",
              },
            }}
          >
            {pageList.map((page) => {
              if (page.link) {
                return (
                  <ListItem
                    disablePadding
                    component={RouterLink}
                    to={page.link}
                    key={page.label}
                    style={{ color: "#fff" }}
                    onClick={handleDrawerToggleInside}
                  >
                    <ListItemButton sx={{ textAlign: "center" }}>
                      <ListItemText primary={page.label} />
                    </ListItemButton>
                  </ListItem>
                );
              } else {
                return (
                  <span key={page.label}>
                    <ListItem
                      disablePadding
                      style={{ color: "#fff" }}
                      onClick={resourceHandleClick}
                    >
                      <ListItemButton sx={{ textAlign: "center" }}>
                        <ListItemText primary={page.label} />
                        {resourceOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                    </ListItem>
                    <Collapse in={resourceOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {page.pages.map((insidePage) => (
                          <ListItem
                            disablePadding
                            component={RouterLink}
                            to={insidePage.link}
                            key={insidePage.label}
                            style={{ color: "#fff" }}
                            onClick={handleDrawerToggleInside}
                          >
                            <ListItemButton sx={{ textAlign: "center" }}>
                              <ListItemText primary={insidePage.label} />
                            </ListItemButton>
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  </span>
                );
              }
            })}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};

export default NavDrawer;
