import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";

import { pageList } from "../";
import Logo from "./angelia_white.svg";

const NavBar = ({ handleDrawerToggle }: { handleDrawerToggle: () => void }) => {
  const [resourceAnchorEl, setResourceAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const resourceOpen = Boolean(resourceAnchorEl);
  const resourceHandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setResourceAnchorEl(event.currentTarget);
  };
  const resourceHandleClose = () => {
    setResourceAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Button
              component={RouterLink}
              to={{ pathname: "/" }}
              sx={{ color: "#fff" }}
            >
              <img alt="Angelia" src={Logo} width={101} height={68} />
            </Button>
            {pageList.map((page) => {
              if (page.link) {
                return (
                  <Button
                    component={RouterLink}
                    to={page.link}
                    key={page.label}
                    sx={{ color: "#fff" }}
                  >
                    {page.label}
                  </Button>
                );
              } else {
                return (
                  <span key={page.label}>
                    <Button
                      sx={{ color: "#fff" }}
                      onClick={resourceHandleClick}
                    >
                      {page.label}
                      {resourceOpen ? <ExpandLess /> : <ExpandMore />}
                    </Button>
                    <Menu
                      anchorEl={resourceAnchorEl}
                      open={resourceOpen}
                      onClose={resourceHandleClose}
                    >
                      {page.pages.map((insidePage, index) => (
                        <MenuItem
                          key={index}
                          component={RouterLink}
                          to={insidePage.link}
                          onClick={resourceHandleClose}
                        >
                          {insidePage.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </span>
                );
              }
            })}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
