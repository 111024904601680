import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { Box, createTheme, CssBaseline, ThemeOptions } from "@mui/material";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";

import App from "./App";

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#011696",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    h1: { fontFamily: "Conthrax", fontSize: "56" },
    h2: {
      fontSize: "42",
      fontWeight: "500",
      color: "#011696",
      textAlign: "center",
    },
    subtitle1: { fontSize: "25" },
    h6: { fontFamily: "Conthrax", fontSize: "22" },
  },
  components: {
    MuiList: {
      styleOverrides: {
        root: { color: "#ffffff", backgroundColor: "#011696" },
      },
    },
    MuiTypography: { defaultProps: { variantMapping: { subtitle1: "p" } } },
  },
};
const theme = createTheme(themeOptions);

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? ""}
      language="fr"
      useRecaptchaNet
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <ThemeProvider theme={responsiveFontSizes(theme)}>
              <CssBaseline />
              <App />
            </ThemeProvider>
          </Box>
        </BrowserRouter>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>
);
