import { useState } from "react";

import { Box } from "@mui/material";

import NavBar from "./NavBar";
import NavDrawer from "./NavDrawer";

const NavMenu = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <NavBar handleDrawerToggle={handleDrawerToggle} />
      <NavDrawer
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />
    </Box>
  );
};

export default NavMenu;
